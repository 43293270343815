<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw">
          <div class="title">我关注的专家</div>
          <div class="content">
            <div class="box" v-for="(v, i) in expertList" :key="i">
              <div class="top">
                <img :src="v.info.avatar" alt />
                <div class="fr">
                  <p class="p1 one">
                    {{ v.info.realname }}
                    <span>专家</span>
                  </p>
                  <p class="p2 one">{{ v.info.jigou_name }}</p>
                </div>
              </div>
              <div class="bottom">
                <p @click="cancleFollow(v)">取消关注</p>
                <p @click="immedInquiry(v)">立即问诊</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部

import { usergetinfo } from "@/request/user";
import { getAttentionList } from "@/request/user";
import { addFav } from "@/request/user";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      expertList: [] // 关注的专家列表
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.start();
    // this.state=this.$route.query.state
  },
  methods: {
    start() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
      //   关注的专家列表
      getAttentionList({
        token: this.$token,
        type: 2
      }).then(res => {
        this.expertList = res.data.data;
        console.log(this.expertList);
        if (this.expertList.length == 0) {
          this.$message({
            type: "success",
            message: "暂时没有关注的专家"
          });
        }
      });
    },
    cancleFollow(v) {
      addFav({
        doctor_uid: v.info.uid,
        token: this.$token
      }).then(res => {
        if (res.code == 1) {
          let index = this.expertList.indexOf(v);
          this.expertList.splice(index, 1);
        }
      });
    },
    immedInquiry(v) {
      let id = v.info.id;
      let uid = v.info.uid;
      this.$router.push(`/expert/expertDetails?id=${id}&uid=${uid}`);
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 400px;
  height: 240px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-top: none;
  padding-left: 56px;
  padding-top: 46px;
  img {
    width: 106px;
    height: 106px;
  }
  .fr {
    width: 200px;
    margin-right: 20px;
    padding-top: 24px;
  }
  .p1 {
    font-size: 18px;
    span {
      font-size: 16px;
      color: #4eeec8;
    }
  }
  .p2 {
    margin-top: 24px;
    font-size: 16px;
    color: #4d4d4d;
  }
  .bottom {
    margin-top: 24px;
    padding-right: 56px;
    display: flex;
    justify-content: space-around;
    p {
      width: 130px;
      height: 40px;
      line-height: 40px;
      background: rgba(78, 238, 200, 1);
      color: white;
      text-align: center;
      cursor: pointer;
    }
    p:nth-of-type(2) {
      background: white;
      color: rgba(78, 238, 200, 1);
      border: 1px solid rgba(78, 238, 200, 1);
    }
  }
}
.content {
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
}
.title {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  text-indent: 36px;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
}
</style>